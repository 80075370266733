
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















.feedback-message,
[class*='feedback-message--'] {
  display: flex;
  align-items: center;
  color: $c-green;
  font-size: 1.5rem;
  line-height: 1.1;
  fill: $c-green;

  &[class*='--filled'] {
    padding: 1rem 1.5rem;
    border-radius: 8px;

    &.is-success {
      background: rgba($c-green, 0.3);
    }

    &.is-warning {
      background: rgba($c-yellow, 0.1);
    }

    &.is-error {
      background: rgba($c-red, 0.3);
    }
  }

  &.is-success {
    color: $c-green;
    fill: $c-green;
  }

  &.is-warning {
    color: $c-yellow;
    fill: $c-yellow;
  }

  &.is-error {
    color: $c-red;
    fill: $c-red;
  }
}

.icon {
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}
