
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















































































































































































































































































































































































































































::v-deep .fatline,
.password-subtitle {
  color: $c-main-primary;
}

::v-deep .fatline::after {
  background-color: $c-main-primary;
}

.password-summary {
  margin-bottom: 40px;
}

.submit {
  @include loading($c-orange);

  display: flex !important; /* stylelint-disable-line declaration-no-important */
  margin-left: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.nav__message {
  min-width: 30rem;
  margin-right: 2rem;

  @include mq(l) {
    flex-basis: 60%;
  }
}
