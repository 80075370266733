
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





.is-loading-message {
  font-size: 1.6rem;
  padding-top: 1rem;
  padding-right: 5rem;
  padding-bottom: 1rem;
  padding-left: 5rem;
  background: #fff url('/assets/images/loader.svg') no-repeat left center;
}
